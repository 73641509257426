"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupCreditCard = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const useABTest_1 = require("../../../../hooks/useABTest");
const PaymentGroupCreditCardContainer_1 = require("../../../../containers/buy/PaymentGroupCreditCardContainer");
const SessionSelector_1 = require("../../../../redux/session/selectors/SessionSelector");
const useSaferpay_1 = require("../../../../hooks/useSaferpay");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const CheckoutPaymentContext_1 = require("../../../../context/CheckoutPaymentContext");
const SagaContextSelectors_1 = require("../../../../redux/SagaContextSelectors");
const NewCreditCardForm_1 = require("./credit-card/NewCreditCardForm");
const SavedCreditCardForm_1 = require("./credit-card/SavedCreditCardForm");
const PaymentGroupContent_1 = require("./PaymentGroupContent");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const PaymentGroupCreditCard = ({ disabled, selected, group }) => {
    const paymentGroupContentRef = (0, react_1.useRef)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, SagaContextSelectors_1.getServicesEndpoint)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const disableAmexPaymentCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.DISABLE_AMEX_PAYMENT);
    const [{ optionError }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const optionSelectError = optionError[group.name];
    const paymentMethods = group.paymentOptions.map(option => option.name.toLowerCase());
    let groupOgoneAliases = group.ogoneAliases || [];
    if (disableAmexPaymentCookie) {
        groupOgoneAliases = groupOgoneAliases.filter(card => card.brand !== PaymentGroupCreditCardContainer_1.AMEX_BRAND);
    }
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [showError, setShowError] = (0, react_1.useState)(false);
    const [saveCreditCardForFutureUse, setSaveCreditCardForFutureUse] = (0, react_1.useState)(false);
    const [selectedSavedCard, setSelectedSavedCard] = (0, react_1.useState)(groupOgoneAliases[0]);
    const [isAddNewCardSelected, setIsAddNewCardSelected] = (0, react_1.useState)(!groupOgoneAliases.length);
    const hasSavedCards = groupOgoneAliases.length > 0;
    const { hasError: hasSaferpayError, validation } = (0, useSaferpay_1.useSaferpay)({
        paymentMethods,
        cvvPlaceholder: true,
        enabled: selected && isAddNewCardSelected,
    });
    const { postSaferpayAlias, postSaferpayToken } = (0, common_queries_1.checkoutMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutate: mutatePostSaferpayAlias } = (0, react_query_1.useMutation)(postSaferpayAlias);
    const { mutate: mutatePostSaferpayToken } = (0, react_query_1.useMutation)(postSaferpayToken);
    const handleSaveCreditCardChange = () => setSaveCreditCardForFutureUse(prev => !prev);
    const handleSelectedCardChange = (card, isAddNewCardSelected) => {
        if (card)
            setSelectedSavedCard(card);
        setIsAddNewCardSelected(isAddNewCardSelected);
    };
    const handleEditSelectedCardEnabled = () => {
        setShowError(false);
    };
    const submitWithSavedCard = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        mutatePostSaferpayAlias(selectedSavedCard.id, {
            onSuccess: ({ redirectUrl }) => {
                location.href = redirectUrl;
            },
            onError: () => {
                setIsSubmitting(false);
                setShowError(true);
            },
        });
    });
    const submitWithNewCard = () => {
        window.SaferpayFields.submit({
            onSuccess: (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ token }) {
                mutatePostSaferpayToken({ token, saveForFutureUse: saveCreditCardForFutureUse }, {
                    onSuccess: ({ redirectUrl }) => {
                        location.href = redirectUrl;
                    },
                    onError: () => {
                        setIsSubmitting(false);
                        setShowError(true);
                    },
                });
            }),
            onError: () => {
                ScrollUtil_1.ScrollUtil.scrollToElement(paymentGroupContentRef.current, true);
                setIsSubmitting(false);
            },
        });
    };
    const onHandleSubmit = () => {
        setIsSubmitting(true);
        setShowError(false);
        dispatch(AnalyticsActions_creators_1.analyticsActions.paymentRequest({ paymentGroup: common_types_1.PaymentGroupName.CREDITCARD }));
        if (isAddNewCardSelected) {
            submitWithNewCard();
        }
        else {
            submitWithSavedCard();
        }
    };
    const isSubmitDisabled = Boolean(disabled || !!hasSaferpayError || showError);
    const newCreditCardFormProps = {
        paymentMethods,
        validation,
        isSubmitDisabled,
        isSubmitting,
        showSaveCardBlock: Boolean(isLoggedIn && group.supportsTokenization),
        saveCreditCard: saveCreditCardForFutureUse,
        onSaveCreditCardChange: handleSaveCreditCardChange,
    };
    return ((0, jsx_runtime_1.jsx)(PaymentGroupContent_1.PaymentGroupContent, { onSubmit: onHandleSubmit, selected: selected, group: group, disabled: isSubmitDisabled, isSubmitting: isSubmitting, error: optionSelectError || hasSaferpayError || showError ? t('buy.payment.form.error') : undefined, innerRef: paymentGroupContentRef, children: selected &&
            (hasSavedCards ? ((0, jsx_runtime_1.jsx)(SavedCreditCardForm_1.SavedCreditCardForm, { cards: groupOgoneAliases, selectedCard: selectedSavedCard, onSelectedCardChange: handleSelectedCardChange, onSubmit: onHandleSubmit, isSubmitting: isSubmitting, isSubmitDisabled: isSubmitDisabled, onEditClick: handleEditSelectedCardEnabled, hasSubmitError: Boolean(hasSaferpayError || showError), newCreditCardFormProps: Object.assign(Object.assign({}, newCreditCardFormProps), { onSubmit: onHandleSubmit }) })) : ((0, jsx_runtime_1.jsx)(NewCreditCardForm_1.NewCreditCardForm, Object.assign({}, newCreditCardFormProps)))) }));
};
exports.PaymentGroupCreditCard = PaymentGroupCreditCard;
