"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupKlarna = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const PaymentGroupContent_1 = require("./PaymentGroupContent");
const CheckoutPaymentContext_1 = require("../../../../context/CheckoutPaymentContext");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const KlarnaUtil_1 = require("../../../../util/KlarnaUtil");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const useSavePaymentOption_1 = require("../../../../hooks/useSavePaymentOption");
const SCRIPT_KEY = 'klarna-payments-sdk-js';
const PaymentGroupKlarna = ({ disabled, selected, group }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [isKlarnaLoaded, setIsKlarnaLoaded] = (0, react_1.useState)(false);
    const [hasError, setHasError] = (0, react_1.useState)(false);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const { paymentsScriptUrl } = (0, ConfigurationUtil_1.getKlarnaConfig)(aemConfiguration);
    const { savePaymentOptions, errorMessage, isLoading } = (0, useSavePaymentOption_1.useSavePaymentOption)();
    const [{ info, optionError, klarnaToken }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const optionSelectError = optionError[group.name];
    const klarnaError = hasError ? t('buy.payment.form.error') : undefined;
    const initKlarna = (token) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield KlarnaUtil_1.default.init({
                token,
                url: paymentsScriptUrl,
                key: SCRIPT_KEY,
            });
            KlarnaUtil_1.default.load('#klarna_container', [group.paymentOptions[0].paymentMethodCode], info.orderReference, () => {
                setIsKlarnaLoaded(true);
            });
        }
        catch (_a) {
            setIsKlarnaLoaded(false);
            setHasError(true);
        }
    });
    (0, react_1.useEffect)(() => {
        if (!klarnaToken) {
            return;
        }
        initKlarna(klarnaToken);
    }, [klarnaToken]);
    return ((0, jsx_runtime_1.jsxs)(PaymentGroupContent_1.PaymentGroupContent, { onSubmit: () => savePaymentOptions({ group }), disabled: disabled, isSubmitting: isLoading, error: errorMessage || optionSelectError || klarnaError, selected: selected, group: group, children: [!klarnaToken && !isKlarnaLoaded && !hasError && !optionSelectError && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", itemsAlign: "center", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Spinner, { size: "small", fitContainer: true }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.klarna.loading.message`) })] })), klarnaToken && (0, jsx_runtime_1.jsx)("div", { id: "klarna_container", style: { display: isKlarnaLoaded ? 'block' : 'none' } })] }));
};
exports.PaymentGroupKlarna = PaymentGroupKlarna;
