"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySubmitForm = exports.setInvoiceValues = exports.updateAddressCountryValues = exports.initialValuesDeliveryForm = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const react_router_1 = require("react-router");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const andes_react_1 = require("@yonderland/andes-react");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const axios_1 = require("axios");
const BasketUtil_1 = require("../../../util/BasketUtil");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const addressFields_utils_1 = require("../../form/address-fields/addressFields.utils");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const useScrollToFirstFormError_1 = require("../../form/hooks/useScrollToFirstFormError");
exports.initialValuesDeliveryForm = {
    idTitle: undefined,
    firstName: '',
    middleName: '',
    lastName: '',
    address: addressFields_utils_1.emptyDeliveryAddressFields,
    showCustomTown: false,
    needInvoice: false,
    alternateInvoiceAddress: false,
    invoice: {
        vatCountry: '',
        vatNumber: '',
        companyName: '',
        companyDepartment: '',
        address: addressFields_utils_1.emptyAddressFields,
    },
};
const updateAddressCountryValues = (initialValues, idCountry) => {
    if (![common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.FR].includes(idCountry)) {
        initialValues.address.houseNumberAddition = '';
    }
    if (![common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.DE, common_types_1.CountryId.FR].includes(idCountry)) {
        initialValues.address.houseNumber = '';
    }
    if ([common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.DE, common_types_1.CountryId.FR].includes(idCountry)) {
        initialValues.address.addressLine2 = '';
    }
    if (![common_types_1.CountryId.GB, common_types_1.CountryId.GG, common_types_1.CountryId.JE].includes(idCountry)) {
        initialValues.address.addressLine3 = '';
    }
    return initialValues;
};
exports.updateAddressCountryValues = updateAddressCountryValues;
const setInvoiceValues = (customerDetails, idCountry) => {
    var _a, _b, _c, _d;
    return ({
        needInvoice: customerDetails.needInvoice,
        invoice: {
            vatCountry: ((_a = customerDetails.vatNumber) === null || _a === void 0 ? void 0 : _a.slice(0, 2)) || '',
            vatNumber: (_b = customerDetails.vatNumber) !== null && _b !== void 0 ? _b : '',
            companyName: (_c = customerDetails.companyName) !== null && _c !== void 0 ? _c : '',
            companyDepartment: (_d = customerDetails.companyDepartment) !== null && _d !== void 0 ? _d : '',
            address: Object.assign(Object.assign({}, customerDetails.invoiceAddress), { idCountry: idCountry }),
        },
    });
};
exports.setInvoiceValues = setInvoiceValues;
const DeliverySubmitForm = ({ children, validationSchema, initialValues, handleFormData, onInvalidForm, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const inAuthorMode = (0, ConfigurationUtil_1.getInAuthorMode)(aemConfiguration);
    const history = (0, react_router_1.useHistory)();
    const [{ info, activeDeliveryOption }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const shouldValidate = (0, react_1.useRef)(true);
    const queryClient = (0, react_query_1.useQueryClient)();
    const { postDeliveryOption, postDelivery } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutate: postDeliveryOptionMutation } = (0, react_query_1.useMutation)(postDeliveryOption);
    const { mutate: postDeliveryMutation, isPending, isError } = (0, react_query_1.useMutation)(postDelivery);
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: (0, react_1.useMemo)(() => initialValues, [initialValues]),
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: validationSchema && (0, yup_1.yupResolver)(validationSchema),
    });
    (0, useScrollToFirstFormError_1.useScrollToFirstFormError)(formProps.formState);
    (0, react_1.useEffect)(() => {
        formProps.reset(Object.assign({}, initialValues));
    }, [initialValues]);
    (0, react_1.useEffect)(() => {
        const validateForm = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (!validationSchema) {
                return;
            }
            const isValid = yield validationSchema.isValid(initialValues, { abortEarly: false });
            if (!isValid) {
                onInvalidForm === null || onInvalidForm === void 0 ? void 0 : onInvalidForm();
            }
            shouldValidate.current = false;
        });
        if (initialValues && shouldValidate.current) {
            validateForm();
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (isError) {
            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
        }
    }, [isError]);
    const onSubmit = values => {
        SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA);
        const data = handleFormData(values);
        if (info.chosenDeliveryOption) {
            postDeliveryOptionMutation(info.chosenDeliveryOption);
        }
        postDeliveryMutation(data, {
            onSuccess: () => {
                queryClient.refetchQueries({
                    queryKey: common_queries_1.checkoutQueryKeys.getPaymentInfo(),
                    stale: false,
                });
                history.push((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.PAYMENT}`, inAuthorMode));
            },
            onError: error => {
                var _a;
                if ((0, axios_1.isAxiosError)(error)) {
                    if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                        location.href = `${root}checkout/basket-overview.html`;
                        return;
                    }
                    if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.find((item) => item.code === 'non_numeric_field')) {
                        dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.INVALID_POSTAL_CODE });
                    }
                    else {
                        if ((0, BuyFormUtil_1.isUkCountry)(data.country)) {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY });
                        }
                        else {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
                        }
                    }
                }
            },
        });
    };
    const getButtonState = () => {
        if (isPending) {
            return 'loading';
        }
        if (!activeDeliveryOption && info.requiresDelivery) {
            return 'disabled';
        }
        return 'interactive';
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsxs)("form", { onSubmit: formProps.handleSubmit(onSubmit), noValidate: true, name: "checkout-delivery-details-form", style: { display: 'contents' }, children: [children, (0, jsx_runtime_1.jsx)(andes_react_1.CheckoutLayout.Sticky, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 12, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, lg: 4 }, offset: { lg: 9 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, state: getButtonState(), text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.submit`), theme: "branded", type: "submit", onClick: formProps.handleSubmit(onSubmit) }) }) }) })] }) })));
};
exports.DeliverySubmitForm = DeliverySubmitForm;
