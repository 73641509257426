"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryOption = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const CurrencyUtil_1 = require("../../../../util/CurrencyUtil");
const react_i18next_1 = require("react-i18next");
const asNumeral_1 = require("../../../../vendors/asNumeral");
const react_1 = require("react");
const CollectHQContainer_1 = require("../CollectHQContainer");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const react_redux_1 = require("react-redux");
const BasketActions_creators_1 = require("../../../../redux/basket/actions/BasketActions.creators");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const GeneralUtil_1 = require("../../../../util/GeneralUtil");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const PickupPointContainer_1 = require("../pickup-point/PickupPointContainer");
const HomeDeliveryContainer_1 = require("../home-delivery/HomeDeliveryContainer");
const CollectStoreContainer_1 = require("../collect-store/CollectStoreContainer");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliveryOption = ({ disabled, option, checked, subtitle, exclusionText }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const { displayName, name, priceDelivery, priceCurrency } = option;
    const { contentProps, handleToggle } = (0, andes_react_1.useCollapsible)();
    const [{ activeDeliveryOption, info }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const dispatchRedux = (0, react_redux_1.useDispatch)();
    const selectionCardRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const scrollToCard = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (!selectionCardRef.current)
                return;
            yield (0, GeneralUtil_1.mimicDelay)(GeneralConstants_1.DELAY.DELAY_250);
            ScrollUtil_1.ScrollUtil.scrollToPosition(selectionCardRef.current.offsetTop - window.innerHeight / 3);
        });
        if (checked) {
            scrollToCard();
        }
    }, [checked]);
    const getDeliveryPrice = () => !priceDelivery
        ? t('buy.delivery.price.free')
        : `${(0, CurrencyUtil_1.convertToCurrencySymbol)(priceCurrency)}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(priceDelivery)}`;
    const getContent = (option) => {
        switch (option.name) {
            case common_types_1.DeliveryOptionsEnum.COLLECT_HQ:
                return (0, jsx_runtime_1.jsx)(CollectHQContainer_1.CollectHQContainer, {});
            case common_types_1.DeliveryOptionsEnum.COLLECT_STORE:
                return (0, jsx_runtime_1.jsx)(CollectStoreContainer_1.CollectStoreContainer, {});
            case common_types_1.DeliveryOptionsEnum.HOME_DELIVERY:
            case common_types_1.DeliveryOptionsEnum.NAMED_DAY:
            case common_types_1.DeliveryOptionsEnum.NEXT_DAY:
                return (0, jsx_runtime_1.jsx)(HomeDeliveryContainer_1.HomeDeliveryContainer, {});
            case common_types_1.DeliveryOptionsEnum.PICKUP_POINT:
                return (0, jsx_runtime_1.jsx)(PickupPointContainer_1.PickupPointContainer, {});
            default:
                return (0, jsx_runtime_1.jsx)("h2", { children: "not found" });
        }
    };
    const onChange = (name) => {
        if (checked) {
            return;
        }
        !checked && handleToggle();
        dispatch({ type: 'set_active_delivery_option', payload: option });
        dispatchRedux(AnalyticsActions_creators_1.analyticsActions.deliveryStepOption(name));
        dispatchRedux(BasketActions_creators_1.basketActions.createGetAction());
    };
    const showSubtitle = (option) => subtitle &&
        (!activeDeliveryOption ||
            (activeDeliveryOption &&
                !(activeDeliveryOption.name === option.name && (option === null || option === void 0 ? void 0 : option.name) === common_types_1.DeliveryOptionsEnum.COLLECT_STORE)));
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { innerRef: selectionCardRef, onClick: () => onChange(name), state: disabled ? 'disabled' : checked ? 'active' : 'resting', children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "media", cellProps: { align: 'stretch' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", children: (0, jsx_runtime_1.jsx)(andes_react_1.RadioButton, { id: name, name: "checkout-delivery-options", value: name, checked: checked, onChange: () => onChange(name) }) }) }), (0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard.Cell, { section: "header", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", color: disabled ? 'subdued-2' : undefined, children: displayName }), showSubtitle(option) && (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "success", children: subtitle })] }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "tag", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: disabled ? 'subdued-2' : !priceDelivery ? 'success' : undefined, children: getDeliveryPrice() }) }), exclusionText && ((0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: exclusionText }) })), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, Object.assign({}, contentProps, { isOpen: checked, children: !!info && getContent(option) })) })] }));
};
exports.DeliveryOption = DeliveryOption;
