"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryContainer = exports.getInitialValues = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const CustomerDetailsFields_1 = require("../CustomerDetailsFields");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliverySubmitForm_1 = require("../DeliverySubmitForm");
const CustomerUtil_1 = require("../../../../util/CustomerUtil");
const InvoiceFields_1 = require("../../../form/invoice-fields/InvoiceFields");
const HomeDeliveryDayPreview_1 = require("./HomeDeliveryDayPreview");
const HomeDeliveryDaySelector_1 = require("./HomeDeliveryDaySelector");
const AddressFields_1 = require("../../../form/address-fields/AddressFields");
const HomeDeliveryAddressPreview_1 = require("./HomeDeliveryAddressPreview");
const HomeDeliveryAddressSelector_1 = require("./HomeDeliveryAddressSelector");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const CustomerPhoneField_1 = require("../CustomerPhoneField");
const AddressUtil_1 = require("../../../../util/AddressUtil");
const react_redux_1 = require("react-redux");
const SessionSelector_1 = require("../../../../redux/session/selectors/SessionSelector");
const HomeDeliveryPhonePreview_1 = require("./HomeDeliveryPhonePreview");
const HomeDeliveryPhoneSelector_1 = require("./HomeDeliveryPhoneSelector");
const common_types_1 = require("@as-react/common-types");
const invoiceFields_utils_1 = require("../../../form/invoice-fields/invoiceFields.utils");
const CustomerDetailsFields_utils_1 = require("../../../form/registration-fields/CustomerDetailsFields.utils");
const addressFields_utils_1 = require("../../../form/address-fields/addressFields.utils");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const Yup = require("yup");
const common_utils_1 = require("@as-react/common-utils");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const getPreselectedAddress = ({ deliveryAddress, deliveryAddresses, idCountry }, selectedAddress) => {
    if (selectedAddress) {
        return selectedAddress;
    }
    const defaultAddress = deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.find(address => address.defaultAddress);
    const address = (0, AddressUtil_1.hasPrefilledAddress)(deliveryAddress) && !(0, AddressUtil_1.isStoreDeliveryAddress)(deliveryAddress)
        ? deliveryAddress
        : defaultAddress || (deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses[0]);
    return (address === null || address === void 0 ? void 0 : address.idCountry) === idCountry ? address : undefined;
};
const getInitialValues = (delivery, initValues, selectedAddress) => {
    var _a;
    if (!delivery.customerDetails) {
        return initValues;
    }
    const { deliveryAddress, customerDetails } = delivery;
    const preselectedAddress = getPreselectedAddress(delivery, selectedAddress);
    const idCountry = delivery.idCountry;
    const address = preselectedAddress ||
        (((_a = customerDetails.address) === null || _a === void 0 ? void 0 : _a.idCountry) === idCountry
            ? customerDetails.address
            : addressFields_utils_1.emptyDeliveryAddressFields);
    let initialValues = Object.assign(Object.assign(Object.assign({}, initValues), { idTitle: (address === null || address === void 0 ? void 0 : address.idTitle) || customerDetails.idTitle, firstName: (address === null || address === void 0 ? void 0 : address.firstName) || customerDetails.firstName || '', lastName: (address === null || address === void 0 ? void 0 : address.lastName) || customerDetails.lastName || '', middleName: (address === null || address === void 0 ? void 0 : address.middleName) || customerDetails.middleName, address: Object.assign(Object.assign({}, address), { idCountry: idCountry }) }), (0, DeliverySubmitForm_1.setInvoiceValues)(customerDetails, idCountry));
    initialValues = (0, DeliverySubmitForm_1.updateAddressCountryValues)(initialValues, idCountry);
    const phoneNumber = deliveryAddress.phoneNumber || customerDetails.mobilePhoneNumber;
    if (phoneNumber) {
        initialValues = Object.assign(Object.assign({}, initialValues), { phoneNumber: CustomerUtil_1.default.convertPhoneNumberPrefix(phoneNumber, '00', '+') });
    }
    return initialValues;
};
exports.getInitialValues = getInitialValues;
const HomeDeliveryContainer = () => {
    var _a, _b;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [{ info, errors, activeDeliveryOption, openSelector, homeDelivery }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { deliveryForm: { hideInvoice, showMobilePhoneNumber }, } = (0, CheckoutContext_1.useCheckoutConfig)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const fullVatFormEnabled = (0, ConfigurationUtil_1.getEnableFullVatForm)(aemConfiguration);
    const isInvoiceEnabled = CustomerUtil_1.default.isInvoiceEnabled(hideInvoice, info);
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const isNLMarket = defaultRequestParams.market === 'nl';
    const isBEMarket = defaultRequestParams.market === 'be';
    const showPhoneNumberFieldForNLBE = showMobilePhoneNumber && (isNLMarket || isBEMarket) && (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.showPhoneNumber);
    const selectedAddress = getPreselectedAddress(info, homeDelivery.selectedAddress);
    const initialValues = (0, exports.getInitialValues)(info, (0, common_utils_1.mergeObjectDeep)(DeliverySubmitForm_1.initialValuesDeliveryForm, {
        address: { idCountry: info.idCountry },
        invoice: { address: { idCountry: info.idCountry } },
    }), info.idCountry === (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.idCountry) ? selectedAddress : undefined);
    const [showDeliveryAddressFields, setShowDeliveryAddressFields] = (0, react_1.useState)(!isLoggedIn || !!selectedAddress);
    const [saveAddress, setSaveAddress] = (0, react_1.useState)(false);
    const { updatePhoneNumber } = (0, common_queries_1.customerMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutate: updatePhoneNumberMutation } = (0, react_query_1.useMutation)(updatePhoneNumber());
    const customerValidationSchema = (0, CustomerDetailsFields_utils_1.getCustomerDetailsValidationSchema)({ t });
    let validationSchema = customerValidationSchema
        .concat((0, invoiceFields_utils_1.getInvoiceValidationSchema)({ t, country: info.country, fullVatFormEnabled }))
        .concat((0, addressFields_utils_1.getAddressValidationSchema)({ t, country: info.country, prefix: 'address.' }));
    if (showPhoneNumberFieldForNLBE) {
        validationSchema = validationSchema.concat(Yup.object({
            mobilePhoneNumber: Yup.string().test('isPossiblePhoneNumber', t(common_types_1.ErrorMessage.INVALID_PHONE), val => !val || (0, andes_react_1.isPossiblePhoneNumber)(val)),
        }));
    }
    (0, react_1.useEffect)(() => {
        setShowDeliveryAddressFields(!(selectedAddress && selectedAddress.idCountry === info.idCountry));
    }, [selectedAddress, info.idCountry]);
    const handleFormData = (data) => {
        var _a, _b, _c;
        const delivery = Object.assign({}, info);
        if (!delivery.customerDetails) {
            delivery.customerDetails = {};
        }
        if (showPhoneNumberFieldForNLBE &&
            data.savePhoneNumber &&
            data.mobilePhoneNumber &&
            data.mobilePhoneNumber !== ((_a = delivery.customerDetails) === null || _a === void 0 ? void 0 : _a.mobilePhoneNumber)) {
            const phoneNumber = CustomerUtil_1.default.convertPhoneNumberPrefix(data.mobilePhoneNumber, '+', '00');
            delivery.customerDetails.mobilePhoneNumber = phoneNumber;
            delivery.deliveryAddress.phoneNumber = phoneNumber;
            updatePhoneNumberMutation(phoneNumber);
        }
        delivery.deliveryAddress = Object.assign(Object.assign({}, data.address), { addressLine2: data.address.addressLine2, addressLine3: data.address.addressLine3, addressLine4: data.address.addressLine4, addToProfile: saveAddress, idCountry: delivery.idCountry, country: delivery.country, customTown: data.address.customTown, companyName: data.address.companyName, address: data.address.address, houseNumber: data.address.houseNumber, houseNumberAddition: data.address.houseNumberAddition, postCode: data.address.postCode, city: data.address.city, county: data.address.county, state: data.address.state, phoneNumber: CustomerUtil_1.default.convertPhoneNumberPrefix(data.mobilePhoneNumber, '+', '00') || '', firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
        let invoiceAddress = {};
        if ((_c = (_b = delivery.customerDetails) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 : _c.address) {
            invoiceAddress = Object.assign({}, delivery.customerDetails.address);
        }
        if (data.needInvoice) {
            invoiceAddress = Object.assign(Object.assign(Object.assign({}, invoiceAddress), data.invoice.address), { idCountry: delivery.idCountry });
        }
        delivery.customerDetails.invoiceAddress = invoiceAddress;
        delivery.customerDetails.needInvoice = data.needInvoice;
        delivery.customerDetails.alternateInvoiceAddress = data.alternateInvoiceAddress;
        if (data.alternateInvoiceAddress) {
            delivery.customerDetails.invoiceAddress = data.invoice.address;
        }
        delivery.customerDetails.companyDepartment = data.invoice.companyDepartment;
        delivery.customerDetails.companyName = data.invoice.companyName;
        delivery.customerDetails.vatNumber = data.invoice.vatNumber;
        if (CustomerUtil_1.default.isUpdateNamesAndTitleForCustomerDetails(delivery)) {
            delivery.customerDetails = Object.assign(Object.assign({}, delivery.customerDetails), { firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
        }
        return delivery;
    };
    const handleEditHomeDeliveryAddress = () => {
        if (!isLoggedIn || (info.deliveryAddresses && info.deliveryAddresses.length <= 0)) {
            setShowDeliveryAddressFields(true);
            return;
        }
        dispatch({ type: 'set_open_selector', payload: 'delivery-address' });
    };
    const handleEditPhoneNumber = () => {
        dispatch({ type: 'set_open_selector', payload: 'phone-number' });
    };
    const handleInvalidForm = () => {
        setShowDeliveryAddressFields(true);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [activeDeliveryOption && CustomerUtil_1.default.isDeliveredToDeliveryAddress(activeDeliveryOption.name) && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: showDeliveryAddressFields ? '4' : undefined, children: [(0, BuyFormUtil_1.showNamedDays)(activeDeliveryOption) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(HomeDeliveryDayPreview_1.HomeDeliveryDayPreview, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), openSelector === 'delivery-day' && (0, jsx_runtime_1.jsx)(HomeDeliveryDaySelector_1.HomeDeliveryDaySelector, {})] })), info && ((0, jsx_runtime_1.jsxs)(DeliverySubmitForm_1.DeliverySubmitForm, { validationSchema: validationSchema, initialValues: initialValues, handleFormData: handleFormData, onInvalidForm: handleInvalidForm, children: [showDeliveryAddressFields ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "6", children: [(0, jsx_runtime_1.jsx)(CustomerDetailsFields_1.CustomerDetailsFields, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { fullWidth: true, children: (0, jsx_runtime_1.jsx)(AddressFields_1.AddressFields, { country: info.country, prefix: 'address.', isRequired: true }) }), isLoggedIn && ((0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, { id: "save-address", label: t('checkout.address.save'), checked: saveAddress, onChange: () => setSaveAddress(!saveAddress) }))] }) }), (showPhoneNumberFieldForNLBE || isInvoiceEnabled) && (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {})] })) : ((0, jsx_runtime_1.jsx)(HomeDeliveryAddressPreview_1.HomeDeliveryAddressPreview, { address: selectedAddress, onEdit: handleEditHomeDeliveryAddress })), showPhoneNumberFieldForNLBE &&
                                (((_a = info.customerDetails) === null || _a === void 0 ? void 0 : _a.mobilePhoneNumber) ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!showDeliveryAddressFields && (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(HomeDeliveryPhonePreview_1.HomeDeliveryPhonePreview, { phoneNumber: (_b = info.customerDetails) === null || _b === void 0 ? void 0 : _b.mobilePhoneNumber, onEdit: handleEditPhoneNumber })] })) : ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: showDeliveryAddressFields ? undefined : '4', children: (0, jsx_runtime_1.jsx)(CustomerPhoneField_1.CustomerPhoneField, { isRequired: activeDeliveryOption.requiresPhoneNumber, showSavePhoneNumberCheckbox: isLoggedIn }) }))), isInvoiceEnabled && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "4", children: (0, jsx_runtime_1.jsx)(InvoiceFields_1.InvoiceFields, { showPreview: true, country: info.country, fullVatFormEnabled: fullVatFormEnabled }) }))] }))] })), activeDeliveryOption && errors[activeDeliveryOption.name] && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(errors[activeDeliveryOption.name]), status: "error" })), openSelector === 'delivery-address' && ((0, jsx_runtime_1.jsx)(HomeDeliveryAddressSelector_1.HomeDeliveryAddressSelector, { selectedAddressId: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.deliveryAddressId })), openSelector === 'phone-number' && (0, jsx_runtime_1.jsx)(HomeDeliveryPhoneSelector_1.HomeDeliveryPhoneSelector, {})] }));
};
exports.HomeDeliveryContainer = HomeDeliveryContainer;
