"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalDetailsSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const andes_react_1 = require("@yonderland/andes-react");
const CheckoutPaymentContext_1 = require("../../../context/CheckoutPaymentContext");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const PersonalDetailsSummary = () => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [state] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const { getTitles } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: customerTitles } = (0, react_query_1.useQuery)(getTitles({ lang }, {
        enabled: !!state,
    }));
    if (!state) {
        return null;
    }
    const { info: { customerDetails, requiresDelivery }, } = state;
    if (!customerDetails) {
        return null;
    }
    const title = (_a = customerTitles === null || customerTitles === void 0 ? void 0 : customerTitles.options.find(customerTitle => customerTitle.value === customerDetails.idTitle)) === null || _a === void 0 ? void 0 : _a.label;
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "1", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", children: t('buy.personal.data.summary.title') }), (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [title ? `${title} ` : '', customerDetails === null || customerDetails === void 0 ? void 0 : customerDetails.firstName, " ", (customerDetails === null || customerDetails === void 0 ? void 0 : customerDetails.middleName) ? `${customerDetails === null || customerDetails === void 0 ? void 0 : customerDetails.middleName} ` : '', customerDetails === null || customerDetails === void 0 ? void 0 : customerDetails.lastName] }), !requiresDelivery && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "success", dataTestId: "delivery_promise", children: t('buy.payment.delivery.information.evoucher') }))] }));
};
exports.PersonalDetailsSummary = PersonalDetailsSummary;
