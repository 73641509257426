"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewCreditCardForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const SaferpayFieldsCreditCard_1 = require("../../../../form/saferpay-fields/SaferpayFieldsCreditCard");
const CheckoutContext_1 = require("../../../../../context/CheckoutContext");
const NewCreditCardForm = ({ onSaveCreditCardChange, saveCreditCard, showSaveCardBlock, paymentMethods, showSubmit, onSubmit, onCancelClick, isSubmitting, isSubmitDisabled, validation, fullWidth, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const { creditCardSavingLegalMessage } = (0, CheckoutContext_1.useCheckoutConfig)();
    const glossary = [
        {
            field: common_types_1.SaferpayFieldTypeEnum.CVC,
            text: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CVC}.description`),
        },
    ];
    const handleSubmitClick = (e) => {
        e.stopPropagation();
        onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
    };
    const handleCancelClick = (e) => {
        e.stopPropagation();
        onCancelClick === null || onCancelClick === void 0 ? void 0 : onCancelClick();
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 12, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: fullWidth ? { xs: 12 } : { xs: 12, md: 6, lg: 7, xl: 8 }, children: (0, jsx_runtime_1.jsx)(SaferpayFieldsCreditCard_1.SaferpayFieldsCreditCard, { paymentMethods: paymentMethods, validation: validation, glossary: glossary }) }) }), showSaveCardBlock && ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { paddingBlockStart: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, { id: "save-credit-card", name: "save-credit-card", value: "save-credit-card", dataTestId: "save-credit-card", label: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.save.label`), checked: saveCreditCard, onChange: onSaveCreditCardChange }), creditCardSavingLegalMessage && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingInlineStart: "6", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subdued", children: creditCardSavingLegalMessage }) }))] })), showSubmit && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "8", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { state: isSubmitDisabled ? 'disabled' : isSubmitting ? 'loading' : 'interactive', dataTestId: "add-new-card-submit", theme: "branded", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.pay_with_new_card`), onClick: handleSubmitClick }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { dataTestId: "add-new-card-cancel", theme: "branded", variant: "secondary", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.payment.cancel`), onClick: handleCancelClick })] }) }))] }));
};
exports.NewCreditCardForm = NewCreditCardForm;
