"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsBEWithValidation = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const FormField_1 = require("../../FormField");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const useDebouncedQuery_1 = require("../../../../hooks/useDebouncedQuery");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const createMunicipalityOptions = (data) => (data === null || data === void 0 ? void 0 : data.map(item => ({
    text: `${item.postCode} - ${item.subMunicipality}`,
    id: item.subMunicipality,
    postCode: item.postCode,
    city: item.subMunicipality,
}))) || [];
const getAutocompleteItems = (data) => data.map(({ id, text }) => ({ id, text }));
const AddressFieldsBEWithValidation = ({ fields, isRequired = true, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [focusedField, setFocusedField] = (0, react_1.useState)(null);
    const { setValue } = (0, react_hook_form_1.useFormContext)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { lang } = aemConfiguration;
    const { getMunicipality, getStreet, getHouseNumber, getBoxNumber } = (0, common_queries_1.postalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const [addressName, houseNumberName, houseNumberAdditionName, postCodeName, cityName] = fields;
    const [postCode, city, address, houseNumber, houseNumberAddition] = (0, react_hook_form_1.useWatch)({
        name: [postCodeName, cityName, addressName, houseNumberName, houseNumberAdditionName],
    });
    const isFocussed = (fieldName) => Boolean(focusedField === fieldName);
    const { data: postCodes, isFetching: isPostCodesLoading } = (0, useDebouncedQuery_1.useDebouncedQuery)(getMunicipality({ lang, query: postCode }, {
        enabled: isFocussed(postCodeName) && postCode !== '',
        select: createMunicipalityOptions,
    }));
    const { data: cities, isFetching: isCitiesLoading } = (0, useDebouncedQuery_1.useDebouncedQuery)(getMunicipality({ lang, query: (0, BuyFormUtil_1.isValidPostCode)(postCode, 'BE') ? postCode : city, city }, {
        enabled: isFocussed(cityName) && city !== '',
        select: data => (0, BuyFormUtil_1.isValidPostCode)(postCode, 'BE')
            ? createMunicipalityOptions(data).filter(item => item.city.toLowerCase().includes(city.toLowerCase()))
            : createMunicipalityOptions(data),
    }));
    const { data: streets, isFetching: isStreetsLoading } = (0, useDebouncedQuery_1.useDebouncedQuery)(getStreet({ lang, municipality: city, postcode: postCode, query: address }, {
        enabled: isFocussed(addressName) && address !== '' && Boolean(postCode || city),
        select: data => (data === null || data === void 0 ? void 0 : data.map(item => ({ text: item.street, id: item.street }))) || [],
    }));
    const { data: houseNumbers, isFetching: isHouseNumberLoading } = (0, useDebouncedQuery_1.useDebouncedQuery)(getHouseNumber({ lang, municipality: city, postcode: postCode, street: address, query: houseNumber }, {
        enabled: isFocussed(houseNumberName) && houseNumber !== '' && Boolean(address && (postCode || city)),
        select: data => (data === null || data === void 0 ? void 0 : data.map(item => ({ text: item.houseNumber, id: item.houseNumber }))) || [],
    }));
    const { data: boxNumbers, isFetching: isBoxNumberLoading } = (0, useDebouncedQuery_1.useDebouncedQuery)(getBoxNumber({ lang, municipality: city, postcode: postCode, street: address, houseNumber, query: houseNumberAddition }, {
        enabled: isFocussed(houseNumberAdditionName) && Boolean(houseNumber && address && (postCode || city)),
        select: data => (data === null || data === void 0 ? void 0 : data.map(item => ({ text: item.boxNumber, id: item.boxNumber }))) || [],
    }));
    const choosePostCodeFromList = (option) => {
        const { postCode = '', city = '' } = (postCodes === null || postCodes === void 0 ? void 0 : postCodes.find(postCode => postCode.id === option.id)) || {};
        setValue(postCodeName, postCode, { shouldTouch: false, shouldValidate: true });
        setValue(cityName, city, { shouldTouch: false, shouldValidate: true });
    };
    const chooseCityFromList = (option) => {
        const { postCode = '', city = '' } = (cities === null || cities === void 0 ? void 0 : cities.find(postCode => postCode.id === option.id)) || {};
        setValue(postCodeName, postCode, { shouldTouch: false, shouldValidate: true });
        setValue(cityName, city, { shouldTouch: false, shouldValidate: true });
    };
    const chooseStreetFromList = (option) => {
        setValue(addressName, option.id, { shouldTouch: false, shouldValidate: true });
    };
    const chooseHouseNumberFromList = (option) => {
        setValue(houseNumberName, option.id, { shouldTouch: false, shouldValidate: true });
    };
    const chooseBoxNumberFromList = (option) => {
        setValue(houseNumberAdditionName, option.id, { shouldTouch: false, shouldValidate: true });
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { fullWidth: true, columns: 12, gapY: 6, isQueryContainer: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: postCodeName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.AutocompleteAlpha, { dataTestId: "postcode", items: getAutocompleteItems(postCode ? postCodes || [] : []), input: {
                                id: fieldProps.name,
                                name: fieldProps.name,
                                label: t('address.postCode'),
                                placeholder: t('placeholder.postal.code.be'),
                                optionalText: isRequired ? undefined : t('form.field.optional'),
                                value: fieldProps.value || '',
                                error: error,
                                required: isRequired,
                                minLength: 4,
                                maxLength: 50,
                                onFocus: () => setFocusedField(fieldProps.name),
                                onBlur: () => setFocusedField(null),
                            }, onSelectedItemChange: choosePostCodeFromList, onChange: fieldProps.onChange, isLoading: isPostCodesLoading }));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: cityName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.AutocompleteAlpha, { dataTestId: "town_city", items: getAutocompleteItems(city ? cities || [] : []), input: {
                                id: fieldProps.name,
                                name: fieldProps.name,
                                label: t('address.city'),
                                placeholder: t('address.city.choose'),
                                optionalText: isRequired ? undefined : t('form.field.optional'),
                                value: fieldProps.value || '',
                                error: error,
                                required: isRequired,
                                onFocus: () => setFocusedField(fieldProps.name),
                                onBlur: () => setFocusedField(null),
                            }, onSelectedItemChange: chooseCityFromList, onChange: fieldProps.onChange, isLoading: isCitiesLoading }));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.AutocompleteAlpha, { dataTestId: "address_line1", items: addressName ? streets || [] : [], input: {
                                id: fieldProps.name,
                                name: fieldProps.name,
                                label: t('address.street'),
                                optionalText: isRequired ? undefined : t('form.field.optional'),
                                value: fieldProps.value || '',
                                error: error,
                                required: isRequired,
                                maxLength: 500,
                                onFocus: () => setFocusedField(fieldProps.name),
                                onBlur: () => setFocusedField(null),
                            }, onSelectedItemChange: chooseStreetFromList, onChange: fieldProps.onChange, isLoading: isStreetsLoading }));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 6, ['@lg']: 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: houseNumberName, required: isRequired, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.AutocompleteAlpha, { dataTestId: "houseNumber", items: houseNumber ? houseNumbers || [] : [], input: {
                                id: fieldProps.name,
                                name: fieldProps.name,
                                label: t('address.houseNumber'),
                                optionalText: isRequired ? undefined : t('form.field.optional'),
                                value: fieldProps.value || '',
                                error: error,
                                required: isRequired,
                                maxLength: 20,
                                onFocus: () => setFocusedField(fieldProps.name),
                                onBlur: () => setFocusedField(null),
                            }, onSelectedItemChange: chooseHouseNumberFromList, onChange: fieldProps.onChange, isLoading: isHouseNumberLoading }));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 6, ['@lg']: 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: houseNumberAdditionName, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.AutocompleteAlpha, { dataTestId: "houseNumberAddition", items: boxNumbers || [], input: {
                                id: fieldProps.name,
                                name: fieldProps.name,
                                label: t('address.houseNumberAddition'),
                                optionalText: t('form.field.optional'),
                                value: fieldProps.value || '',
                                error: error,
                                maxLength: 20,
                                onFocus: () => setFocusedField(fieldProps.name),
                                onBlur: () => setFocusedField(null),
                            }, onSelectedItemChange: chooseBoxNumberFromList, onChange: fieldProps.onChange, isLoading: isBoxNumberLoading }));
                    } }) })] }));
};
exports.AddressFieldsBEWithValidation = AddressFieldsBEWithValidation;
