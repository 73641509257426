"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectStoreSelected = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const StoreUtil_1 = require("../../../../util/StoreUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const dayjs = require("dayjs");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const utils_1 = require("../../../product-detail/store-finder-popup/utils");
const react_1 = require("react");
const DeliverySelectedLocation_1 = require("../DeliverySelectedLocation");
const common_utils_1 = require("@as-react/common-utils");
const DATE_FORMAT = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
};
const getStyledDate = (date, locale) => {
    const formattedDate = (0, utils_1.formatDate)(date, locale, DATE_FORMAT);
    const day = formattedDate.split(' ');
    const [dayString, dayNumber, month] = day;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!!dayString && (0, common_utils_1.capitalize)(dayString), ' ', (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyXS", as: "span", children: `${dayNumber} ${!!month && (0, common_utils_1.capitalize)(month)}` })] }));
};
const CollectStoreSelected = () => {
    var _a, _b, _c, _d;
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(aemConfiguration);
    const { mainWebShop, locale } = (0, ConfigurationUtil_1.getLocaleInformation)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    const [{ collectStore }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { selectedStore } = collectStore;
    const { getStoreInformation } = (0, common_queries_1.storeQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: storeInformation } = (0, react_query_1.useQuery)(getStoreInformation({
        businessUnitId,
        storeId: (selectedStore || {}).storeId,
        weeks: 2,
    }, {
        enabled: !!(selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.storeId),
    }));
    if (!selectedStore)
        return null;
    const locationName = StoreUtil_1.default.getStoreName(selectedStore, mainWebShop, t);
    const address = (_a = selectedStore.address) === null || _a === void 0 ? void 0 : _a.street;
    const houseNumber = (_b = selectedStore.address) === null || _b === void 0 ? void 0 : _b.number;
    const postcode = (_c = selectedStore.address) === null || _c === void 0 ? void 0 : _c.zipCode;
    const city = (_d = selectedStore.address) === null || _d === void 0 ? void 0 : _d.city;
    const handleEditCollectStore = () => {
        dispatch({ type: 'set_open_selector', payload: 'store-finder' });
    };
    const getOpeningHours = () => {
        const todayDate = dayjs();
        const todayTimeInMinutes = todayDate.hour() * 60 + todayDate.minute();
        const todaySchedule = selectedStore.scheduleEntries;
        const currentlyOpened = !todaySchedule.closed &&
            todayTimeInMinutes >= todaySchedule.openTimeInMinutes &&
            todayTimeInMinutes < todaySchedule.closeTimeInMinutes;
        const openingLaterToday = !todaySchedule.closed && todayTimeInMinutes < todaySchedule.openTimeInMinutes;
        const todayOpeningText = currentlyOpened
            ? t(todaySchedule.isExceptional ? 'store.open.till.exceptionally' : 'store.open.till.text', {
                endHour: todaySchedule.closingHour,
            })
            : openingLaterToday
                ? t('store.open.from.text', { startHour: todaySchedule.openingHour })
                : t('store.closed.txt');
        return ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: todayOpeningText }), storeInformation && ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { itemsAlign: "start", children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", flex: { grow: 0 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 2, gapX: "10", gapY: "0", children: (0, utils_1.filterScheduleEntries)(storeInformation.scheduleEntries).map(schedule => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: getStyledDate(schedule.date, locale) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: schedule.closed ? t('store.closed.txt') : `${schedule.openingHour} - ${schedule.closingHour}` })] }, schedule.date))) }) }) }))] }));
    };
    const directPickup = selectedStore.deliveryUnit === utils_1.DeliveryUnit.HOURS && selectedStore.selectable;
    return ((0, jsx_runtime_1.jsx)(DeliverySelectedLocation_1.DeliverySelectedLocation, { locationName: locationName, addressContent: `${address} ${houseNumber}, ${postcode} ${city}`, openingHoursContent: getOpeningHours(), handleEdit: handleEditCollectStore, additionalContent: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: directPickup ? ((0, jsx_runtime_1.jsx)(andes_react_1.StatusIndicator, { status: "complete", colored: true, children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyM", children: !selectedStore.selectable
                        ? t('store.clickandcollect.noStockAvailable')
                        : selectedStore.deliveryMessage }) })) : ((0, jsx_runtime_1.jsx)(andes_react_1.StatusIndicator, { status: directPickup ? 'complete' : !selectedStore.selectable ? 'incomplete' : 'partiallyComplete', colored: directPickup, children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyM", children: !selectedStore.selectable
                        ? t('store.clickandcollect.noStockAvailable')
                        : selectedStore.deliveryMessage }) })) }) }));
};
exports.CollectStoreSelected = CollectStoreSelected;
