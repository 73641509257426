"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayByVoucher = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useSavePaymentOption_1 = require("../../../hooks/useSavePaymentOption");
const PayByVoucher = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const { savePaymentOptions, isLoading } = (0, useSavePaymentOption_1.useSavePaymentOption)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t('buy.payment.paid.by.voucher.message') }), (0, jsx_runtime_1.jsx)(andes_react_1.CheckoutLayout.Sticky, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 12, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, lg: 4 }, offset: { lg: 9 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, state: isLoading ? 'loading' : 'interactive', theme: "branded", text: t('buy.payment.paid.by.voucher.submit'), onClick: () => savePaymentOptions() }) }) }) })] }));
};
exports.PayByVoucher = PayByVoucher;
