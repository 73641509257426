"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectPaymentOption = void 0;
const tslib_1 = require("tslib");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_query_1 = require("@tanstack/react-query");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const LocalStorageUtil_1 = require("../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const CheckoutPaymentContext_1 = require("../context/CheckoutPaymentContext");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
// should only be used inside PaymentContext
const useSelectPaymentOption = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [, dispatch] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const reduxDispatch = (0, react_redux_1.useDispatch)();
    const { postPaymentOptionId, postPaymentProviderInput } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { postSavePoint } = (0, common_queries_1.basketMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutateAsync: mutatePostPaymentOptionId, isPending: postPaymentOptionPending } = (0, react_query_1.useMutation)(postPaymentOptionId);
    const { mutateAsync: mutatePostSavePoint } = (0, react_query_1.useMutation)(postSavePoint);
    const { mutateAsync: mutatePostPaymentProviderInput } = (0, react_query_1.useMutation)(postPaymentProviderInput);
    const selectPaymentGroup = (group) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const options = group.paymentOptions;
        const isSingleOption = options.length === 1;
        let optionId;
        let provider;
        let optionName;
        try {
            dispatch({ type: 'set_payment_group_success', payload: false });
            if (isSingleOption || group.name === common_types_1.PaymentGroupName.ECOCHEQUE) {
                optionId = options[0].paymentOptionId;
                provider = options[0].provider;
                optionName = options[0].name;
            }
            else {
                const findOption = options.find(opt => opt.name === group.name);
                optionId = findOption === null || findOption === void 0 ? void 0 : findOption.paymentOptionId;
                provider = findOption === null || findOption === void 0 ? void 0 : findOption.provider;
                optionName = findOption === null || findOption === void 0 ? void 0 : findOption.name;
                if (group.supportsTokenization) {
                    yield mutatePostSavePoint();
                    reduxDispatch(AnalyticsActions_creators_1.analyticsActions.paymentStepOption(group.name));
                }
            }
            if (optionId && provider) {
                yield mutatePostPaymentOptionId(optionId);
                yield mutatePostSavePoint();
                dispatch({ type: 'set_payment_group_success', payload: true });
                dispatch({ type: 'set_selected_payment_group', payload: group });
                if (provider === common_types_1.PaymentProvider.KLARNA) {
                    const { clientToken } = yield mutatePostPaymentProviderInput(provider);
                    dispatch({ type: 'set_payment_klarna_token', payload: clientToken });
                }
            }
            else {
                dispatch({ type: 'set_selected_payment_group', payload: group });
            }
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.CHOSEN_PAYMENT_OPTION, group.name.replace(/\s+/g, ''));
            dispatch({ type: 'set_payment_option_error', payload: { [group.name]: undefined } });
            if (optionName) {
                reduxDispatch(AnalyticsActions_creators_1.analyticsActions.paymentStepOption(optionName));
            }
            return true;
        }
        catch (e) {
            dispatch({ type: 'set_payment_option_error', payload: { [group.name]: t(common_types_1.ErrorMessage.GENERAL_PAYMENT) } });
            return false;
        }
    });
    return { selectPaymentGroup, postPaymentOptionPending };
};
exports.useSelectPaymentOption = useSelectPaymentOption;
