"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsUK = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const common_queries_1 = require("@as-react/common-queries");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const addressFields_utils_1 = require("../addressFields.utils");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const FormField_1 = require("../../FormField");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const QueryClientUtil_1 = require("../../../../util/QueryClientUtil");
const useDebouncedQuery_1 = require("../../../../hooks/useDebouncedQuery");
const AddressCard_1 = require("../AddressCard");
const MINIMUM_TERM_LENGTH = 3;
const ADDRESS_RESPONSE_SIZE = 100;
const AddressFieldsUK = ({ prefix, isRequired, hasInitialAddress = false, showAddressCard = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [showAddress, setShowAddress] = (0, react_1.useState)(hasInitialAddress);
    const { setValue, formState } = (0, react_hook_form_1.useFormContext)();
    const { getUKAddressList, getUKAddressDetails } = (0, common_queries_1.generalQueries)({ servicesEndpoint, defaultRequestParams });
    const fieldsName = [
        common_types_1.AddressFieldName.FINDER,
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.ADDRESS_LINE_2,
        common_types_1.AddressFieldName.ADDRESS_LINE_3,
        common_types_1.AddressFieldName.ADDRESS_LINE_4,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.COUNTY,
    ];
    const [finderName, ...addressFields] = (0, ArrayUtil_1.mapWithAssert)(fieldsName, name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const [addressName, addressLine2Name, addressLine3Name, addressLine4Name, postCodeName, cityName, countyName] = addressFields;
    const addressTerm = (0, react_hook_form_1.useWatch)({ name: finderName });
    const { data: addressOptions = [], isFetching: isAddressListLoading } = (0, useDebouncedQuery_1.useDebouncedQuery)(getUKAddressList({ term: addressTerm, size: ADDRESS_RESPONSE_SIZE }, {
        enabled: Boolean(addressTerm) && addressTerm.length >= MINIMUM_TERM_LENGTH,
        select: data => data.map(({ moniker, partialAddress }) => ({ id: moniker, text: partialAddress })),
    }), GeneralConstants_1.DELAY.DELAY_300);
    const chooseUKAddressFromList = (option) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!option)
            return;
        try {
            const details = yield QueryClientUtil_1.default.fetchQuery(getUKAddressDetails({ moniker: String(option.id) }));
            Object.keys(details)
                .filter(key => key !== common_types_1.AddressFieldName.COUNTRY && fieldsName.includes(key))
                .forEach(key => {
                if (details === null || details === void 0 ? void 0 : details[key]) {
                    setValue(`${(0, addressFields_utils_1.generateFieldName)(key, prefix)}`, details === null || details === void 0 ? void 0 : details[key], {
                        shouldValidate: true,
                    });
                }
            });
            setShowAddress(true);
        }
        catch (_a) { }
    });
    (0, react_1.useEffect)(() => {
        if (!(0, common_utils_1.isEmptyObject)(formState.errors)) {
            if (showAddressCard) {
                const hasInvalidFields = addressFields.some(field => Boolean((0, react_hook_form_1.get)(formState.errors, field)));
                hasInvalidFields && setShowAddress(true);
            }
            else {
                setShowAddress(true);
            }
        }
    }, [formState.isSubmitting]);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { children: [(0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: finderName, children: (_a) => {
                    var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                    return ((0, jsx_runtime_1.jsx)(andes_react_1.AutocompleteAlpha, { dataTestId: "finder", items: addressOptions, input: {
                            id: fieldProps.name,
                            name: fieldProps.name,
                            label: t('address.finder'),
                            placeholder: t('address.placeholder.search.address'),
                            value: fieldProps.value || '',
                            error: error,
                            adornmentStart: ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "2", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { size: "sm", color: "subdued-2", source: andes_icons_1.MagnifyingGlassIcon }) })),
                        }, onSelect: chooseUKAddressFromList, onChange: fieldProps.onChange, isLoading: isAddressListLoading }));
                } }), showAddress && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: showAddressCard ? { xs: '3', lg: '4' } : '6', children: (0, jsx_runtime_1.jsx)(andes_react_1.ConditionalWrapper, { condition: showAddressCard, wrapper: children => ((0, jsx_runtime_1.jsx)(AddressCard_1.AddressCard, { country: "UK", prefix: prefix, onSaveClick: isEmpty => isEmpty && setShowAddress(false), addressFields: addressFields, children: children })), children: (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "6", children: (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { fullWidth: true, columns: 12, gapY: 6, isQueryContainer: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressName, required: isRequired, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, required: isRequired, label: (0, common_utils_1.trimAsterisk)(t('address.line1')), optionalText: isRequired ? undefined : t('form.field.optional'), id: fieldProps.name, dataTestId: "address_line1", error: error, maxLength: 500 })));
                                        } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressLine2Name, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('address.line2'), optionalText: t('form.field.optional'), id: fieldProps.name, dataTestId: "address_line2", error: error, maxLength: 500 })));
                                        } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressLine3Name, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('address.line3'), optionalText: t('form.field.optional'), id: fieldProps.name, dataTestId: "address_line3", error: error, maxLength: 500 })));
                                        } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressLine4Name, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('address.line4'), optionalText: t('form.field.optional'), id: fieldProps.name, dataTestId: "address_line4", error: error, maxLength: 500 })));
                                        } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: cityName, required: isRequired, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { required: isRequired, innerRef: ref, label: (0, common_utils_1.trimAsterisk)(t('address.city')), optionalText: isRequired ? undefined : t('form.field.optional'), id: fieldProps.name, dataTestId: "town_city", error: error, maxLength: 50 })));
                                        } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: countyName, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('address.county'), optionalText: t('form.field.optional'), id: fieldProps.name, dataTestId: "county", error: error, maxLength: 50 })));
                                        } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: postCodeName, required: isRequired, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { required: isRequired, innerRef: ref, label: (0, common_utils_1.trimAsterisk)(t('address.postCode')), optionalText: isRequired ? undefined : t('form.field.optional'), placeholder: t('placeholder.postal.code.uk'), id: fieldProps.name, dataTestId: "postcode", error: error, maxLength: 50 })));
                                        } }) })] }) }) }) }))] }));
};
exports.AddressFieldsUK = AddressFieldsUK;
