"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const GeneralConstants_1 = require("../constants/GeneralConstants");
// @ts-expect-error This module is declared with 'export ='
const customParseFormat_1 = require("dayjs/plugin/customParseFormat");
const dayjs = require("dayjs");
const dayjs_1 = require("dayjs");
const duration = require("dayjs/plugin/duration");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(duration);
class DateUtil {
    static formatDate(date, originalFormat, formatBy = GeneralConstants_1.DateFormat.YYYY_MM_DD.value) {
        const finalDate = this.replaceAllDashesBySlashes(date);
        switch (originalFormat) {
            case GeneralConstants_1.DateFormat.DD_MM_YYYY.value:
                return this.format(this.change(finalDate), formatBy);
            default:
                return this.format(finalDate, formatBy);
        }
    }
    static format(date, formatBy) {
        const d = new Date(date);
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = d.getFullYear();
        switch (formatBy) {
            case GeneralConstants_1.DateFormat.DD_MM_YYYY.value:
                month = (month.length < 2 ? '0' : '') + month;
                day = (day.length < 2 ? '0' : '') + day;
                return [day, month, year].join('-');
            case GeneralConstants_1.DateFormat.YYYY_MM_DD.value:
                month = (month.length < 2 ? '0' : '') + month;
                day = (day.length < 2 ? '0' : '') + day;
                return [year, month, day].join('-');
            default:
                return date;
        }
    }
    static convertToDayJsDate(inputValue) {
        const dayJsInstance = (0, dayjs_1.extend)(customParseFormat_1.default);
        if (typeof inputValue === 'string') {
            const possibleDateFormats = ['DD-MM-YYYY', 'YYYY-MM-DD', 'DDMMYYYY'];
            let parsedDate = null;
            possibleDateFormats.forEach(formatDateParser => {
                if (!(!!parsedDate && parsedDate.isValid())) {
                    // @ts-expect-error Type 'Dayjs' has no call signatures.
                    parsedDate = dayJsInstance(inputValue, formatDateParser);
                }
            });
            return parsedDate;
        }
        return new dayjs_1.Dayjs(inputValue);
    }
    static formatTimeStampToDateString(timeStamp, locale, format = {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    }) {
        locale = locale.replace('_', '-');
        if (window.Intl) {
            return new Intl.DateTimeFormat(locale, format).format(timeStamp);
        }
        else {
            return new Date(timeStamp).toLocaleDateString(locale, format);
        }
    }
    static calculateDifferenceInMs(from, till) {
        const dateFrom = new Date(from);
        const dateTill = new Date(till);
        return dateFrom.getTime() - dateTill.getTime();
    }
    static formatTimeStampToSlashedString(timeStamp) {
        let dateString = '';
        if (!timeStamp)
            return dateString;
        const date = new Date(timeStamp);
        dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        return dateString;
    }
    static formatSecondsToTime(seconds) {
        const hours = Math.floor(seconds / 60 / 60);
        const minutes = Math.floor(seconds / 60) - hours * 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
    static getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }
    static convertMillisecondsToDays(milliseconds) {
        // eslint-disable-next-line import/namespace
        return dayjs.duration(milliseconds).asDays();
    }
    static convertMillisecondsToUTCDate(milliseconds, format) {
        return dayjs(milliseconds).utc().format(format);
    }
    static isToday(date) {
        const currentDate = new Date();
        const targetDate = new Date(date);
        return (currentDate.getDate() === targetDate.getDate() &&
            currentDate.getMonth() === targetDate.getMonth() &&
            currentDate.getFullYear() === targetDate.getFullYear());
    }
    static isTomorrow(date) {
        const tomorrow = dayjs().add(1, 'day');
        return dayjs(date).isSame(tomorrow, 'day');
    }
    static change(date) {
        const dateParts = date.split('/');
        return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
    }
    static replaceAllDashesBySlashes(value) {
        return value.replace(/-/g, '/');
    }
}
exports.default = DateUtil;
