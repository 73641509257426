"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPointContainer = exports.getInitialValues = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const PickupPointSelected_1 = require("./PickupPointSelected");
const PickupPointSelector_1 = require("./PickupPointSelector");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const CustomerDetailsFields_1 = require("../CustomerDetailsFields");
const CustomerDetailsPreview_1 = require("../CustomerDetailsPreview");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliverySubmitForm_1 = require("../DeliverySubmitForm");
const CustomerUtil_1 = require("../../../../util/CustomerUtil");
const InvoiceFields_1 = require("../../../form/invoice-fields/InvoiceFields");
const AddressFieldsNL_1 = require("../../../form/address-fields/address-fields-nl/AddressFieldsNL");
const CustomerDetailsFields_utils_1 = require("../../../form/registration-fields/CustomerDetailsFields.utils");
const addressFields_utils_1 = require("../../../form/address-fields/addressFields.utils");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const invoiceFields_utils_1 = require("../../../form/invoice-fields/invoiceFields.utils");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const getInitialValues = (delivery, initValues, optionName) => {
    if (!delivery.customerDetails) {
        return initValues;
    }
    const { customerDetails, idCountry } = delivery;
    const address = delivery.customerDetails.address;
    let initialValues = Object.assign(Object.assign(Object.assign(Object.assign({}, initValues), { address: Object.assign(Object.assign({}, address), { idCountry: idCountry }) }), (0, DeliverySubmitForm_1.setInvoiceValues)(customerDetails, idCountry)), CustomerUtil_1.default.getCustomerNames(delivery, optionName));
    initialValues = (0, DeliverySubmitForm_1.updateAddressCountryValues)(initialValues, idCountry);
    return initialValues;
};
exports.getInitialValues = getInitialValues;
const PickupPointContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [{ info, errors, activeDeliveryOption, pickupPoint, openSelector }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { deliveryForm: { hideInvoice }, } = (0, CheckoutContext_1.useCheckoutConfig)();
    const { setLocationSearch } = (0, CheckoutDeliveryContext_1.usePickupPointSearchContext)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const buyDelivery = (0, ConfigurationUtil_1.getBuyDelivery)(aemConfiguration);
    const fullVatFormEnabled = (0, ConfigurationUtil_1.getEnableFullVatForm)(aemConfiguration);
    const isInvoiceEnabled = CustomerUtil_1.default.isInvoiceEnabled(hideInvoice, info);
    const { getPickupPoints } = (0, common_queries_1.checkoutQueries)({ servicesEndpoint, defaultRequestParams });
    const { selectedPickupPoint } = pickupPoint;
    const { pickupInformation, country } = info;
    const isProviderPostNL = (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.provider) === common_types_1.Provider.POSTNL;
    const [showCustomerDetailsForm, setShowCustomerDetailsForm] = (0, react_1.useState)(false);
    const customerValidationSchema = (0, CustomerDetailsFields_utils_1.getCustomerDetailsValidationSchema)({ t });
    const tempValidationSchema = isProviderPostNL
        ? customerValidationSchema.concat((0, addressFields_utils_1.getAddressValidationSchema)({ t, country: info.country, prefix: 'address.' }))
        : customerValidationSchema;
    const validationSchema = isInvoiceEnabled
        ? tempValidationSchema.concat((0, invoiceFields_utils_1.getInvoiceValidationSchema)({ t, country, fullVatFormEnabled }))
        : tempValidationSchema;
    const initialValues = (0, exports.getInitialValues)(info, Object.assign(Object.assign({}, DeliverySubmitForm_1.initialValuesDeliveryForm), { idCountry: info.idCountry }), info.chosenDeliveryOption);
    const queryClient = (0, react_query_1.useQueryClient)();
    const validateActivePickupPoint = (activeDeliveryOption, pickupInformation) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const data = yield queryClient.fetchQuery(getPickupPoints({
                countryCode: country,
                provider: activeDeliveryOption.provider,
                postcode: pickupInformation.properties.customerPostalCode,
                deliveryDate: activeDeliveryOption.sendDate,
                limit: buyDelivery.maxPickupPoints,
            }));
            if (data.data) {
                const activePoint = (_a = data.data) === null || _a === void 0 ? void 0 : _a.find(point => point.id === pickupInformation.properties.locationId);
                if (activePoint) {
                    dispatch({ type: 'select_pickup_point', payload: activePoint });
                    return;
                }
            }
            dispatch({ type: 'set_open_selector', payload: 'pickup-point' });
            resetPickupPoint();
        }
        catch (error) {
            dispatch({ type: 'set_open_selector', payload: undefined });
            resetPickupPoint();
        }
    });
    (0, react_1.useEffect)(() => {
        if (!country || !activeDeliveryOption || !pickupInformation) {
            return;
        }
        validateActivePickupPoint(activeDeliveryOption, pickupInformation);
    }, [country]);
    (0, react_1.useEffect)(() => {
        if (selectedPickupPoint ||
            !activeDeliveryOption ||
            (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name) !== common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
            return;
        }
        if (!pickupInformation && !selectedPickupPoint) {
            dispatch({ type: 'set_open_selector', payload: 'pickup-point' });
            return;
        }
        if (pickupInformation) {
            validateActivePickupPoint(activeDeliveryOption, pickupInformation);
        }
    }, [activeDeliveryOption, pickupInformation]);
    const resetPickupPoint = () => {
        setLocationSearch('');
        dispatch({ type: 'select_pickup_point', payload: null });
        dispatch({ type: 'highlight_pickup_point', payload: null });
    };
    const handleEditCustomerDetails = () => {
        setShowCustomerDetailsForm(true);
    };
    const handleInvalidForm = () => {
        setShowCustomerDetailsForm(true);
    };
    const handleFormData = (data) => {
        var _a, _b;
        const delivery = Object.assign({}, info);
        if (!delivery.customerDetails) {
            delivery.customerDetails = {};
        }
        delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
        if (isProviderPostNL) {
            delivery.customerDetails.address = Object.assign(Object.assign({}, delivery.customerDetails.address), { postCode: data.address.postCode, houseNumber: data.address.houseNumber, houseNumberAddition: data.address.houseNumberAddition, address: data.address.address, city: data.address.city });
        }
        let invoiceAddress = {};
        if ((_b = (_a = delivery.customerDetails) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.address) {
            invoiceAddress = Object.assign({}, delivery.customerDetails.address);
        }
        delivery.customerDetails.invoiceAddress = invoiceAddress;
        delivery.customerDetails.needInvoice = data.needInvoice;
        delivery.customerDetails.companyDepartment = data.invoice.companyDepartment;
        delivery.customerDetails.companyName = data.invoice.companyName;
        delivery.customerDetails.vatNumber = data.invoice.vatNumber;
        if (CustomerUtil_1.default.isUpdateNamesAndTitleForCustomerDetails(delivery)) {
            delivery.customerDetails = Object.assign(Object.assign({}, delivery.customerDetails), { firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, idTitle: data.idTitle });
        }
        if (delivery.pickupInformation) {
            delivery.pickupInformation.properties.countryId = String(data.idCountry);
        }
        return delivery;
    };
    return activeDeliveryOption && activeDeliveryOption.name === common_types_1.DeliveryOptionsEnum.PICKUP_POINT ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [selectedPickupPoint && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "2", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: showCustomerDetailsForm ? '4' : undefined, children: [(0, jsx_runtime_1.jsx)(PickupPointSelected_1.PickupPointSelected, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsxs)(DeliverySubmitForm_1.DeliverySubmitForm, { validationSchema: validationSchema, initialValues: initialValues, handleFormData: handleFormData, onInvalidForm: handleInvalidForm, children: [showCustomerDetailsForm ? ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", spacingClasses: "a-w-full", children: [(0, jsx_runtime_1.jsx)(CustomerDetailsFields_1.CustomerDetailsFields, {}), isProviderPostNL && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(AddressFieldsNL_1.AddressFieldsNL, { prefix: "address." })] }))] })) : ((0, jsx_runtime_1.jsx)(CustomerDetailsPreview_1.CustomerDetailsPreview, { onEdit: handleEditCustomerDetails })), isInvoiceEnabled && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "4", children: (0, jsx_runtime_1.jsx)(InvoiceFields_1.InvoiceFields, { showPreview: true, country: info.country, fullVatFormEnabled: fullVatFormEnabled }) }))] })] }) }) })), activeDeliveryOption && errors[activeDeliveryOption.name] && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(errors[activeDeliveryOption.name]), status: "error" })), openSelector === 'pickup-point' && (0, jsx_runtime_1.jsx)(PickupPointSelector_1.PickupPointSelector, {})] })) : null;
};
exports.PickupPointContainer = PickupPointContainer;
