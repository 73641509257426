"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroups = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const PaymentGroupUtil_1 = require("../../../util/PaymentGroupUtil");
const CheckoutPaymentContext_1 = require("../../../context/CheckoutPaymentContext");
const react_1 = require("react");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const PaymentGroup_1 = require("./payment-groups/PaymentGroup");
const PaymentGroupTitle_1 = require("./PaymentGroupTitle");
const useSelectPaymentOption_1 = require("../../../hooks/useSelectPaymentOption");
const IMAGE_PREFIX = '/content/dam/general/payment_logos/2025/';
const PaymentGroups = () => {
    const [{ info, selectedPaymentGroup }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const { paymentGroups, chosenPaymentOptionId } = info;
    const { selectPaymentGroup, postPaymentOptionPending } = (0, useSelectPaymentOption_1.useSelectPaymentOption)();
    const [isInitialGroupSet, setIsInitialGroupSet] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!paymentGroups || !chosenPaymentOptionId || isInitialGroupSet)
            return;
        const selectedGroup = paymentGroups.find(group => group.paymentOptions.find(option => option.paymentOptionId === chosenPaymentOptionId));
        setIsInitialGroupSet(true); // to avoid api calls duplication after payment info updated
        selectedGroup && selectPaymentGroup(selectedGroup);
    }, [paymentGroups, chosenPaymentOptionId]);
    (0, react_1.useEffect)(() => {
        const element = document.getElementById(`${selectedPaymentGroup === null || selectedPaymentGroup === void 0 ? void 0 : selectedPaymentGroup.name}_${selectedPaymentGroup === null || selectedPaymentGroup === void 0 ? void 0 : selectedPaymentGroup.sortOrder}`);
        if (!element)
            return;
        ScrollUtil_1.ScrollUtil.scrollToPosition(element.offsetTop);
    }, [selectedPaymentGroup]);
    if (!paymentGroups) {
        return null;
    }
    const handlePaymentGroupSelect = (group) => {
        !isInitialGroupSet && setIsInitialGroupSet(true);
        selectPaymentGroup(group);
    };
    const webImagesList = (group) => Array.from(new Set(group.paymentOptions.map(item => item.webImage)));
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "3", children: paymentGroups.map(group => {
            const disabled = postPaymentOptionPending || !(0, PaymentGroupUtil_1.isAllPaymentOptionsValid)(group);
            const selected = group.name === (selectedPaymentGroup === null || selectedPaymentGroup === void 0 ? void 0 : selectedPaymentGroup.name);
            return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { onClick: selected ? undefined : () => handlePaymentGroupSelect(group), id: `${group.name}_${group.sortOrder}`, state: disabled ? 'disabled' : selected ? 'active' : 'resting', hasGapY: false, children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "media", cellProps: { align: 'stretch' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", children: (0, jsx_runtime_1.jsx)(andes_react_1.RadioButton, { id: group.name, name: "checkout-delivery-options", value: group.name, checked: selected, disabled: disabled, onChange: () => handlePaymentGroupSelect(group) }) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", cellProps: { align: 'center' }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", wrap: "nowrap", contentAlign: "between", children: [(0, jsx_runtime_1.jsx)(PaymentGroupTitle_1.PaymentGroupTitle, { group: group, disabled: disabled }), (0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { gap: "2", contentAlign: "end", itemsAlign: "start", children: webImagesList(group).map(image => ((0, jsx_runtime_1.jsx)(andes_react_1.Image, { width: 36, height: 24, alt: group.name, source: (0, PaymentGroupUtil_1.getAndesPaymentGroupImageUrl)(image, IMAGE_PREFIX) }, image))) })] }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsx)(PaymentGroup_1.PaymentGroup, { group: group, disabled: disabled, selected: selected }) })] }, `${group.name}_${group.sortOrder}`));
        }) }));
};
exports.PaymentGroups = PaymentGroups;
