"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const FormField_1 = require("../../components/form/FormField");
const react_hook_form_1 = require("react-hook-form");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const react_redux_1 = require("react-redux");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const CheckoutPaymentContext_1 = require("../../context/CheckoutPaymentContext");
const react_i18next_1 = require("react-i18next");
const Yup = require("yup");
const yup_1 = require("@hookform/resolvers/yup");
const QueryClientUtil_1 = require("../../util/QueryClientUtil");
const common_queries_1 = require("@as-react/common-queries");
const useOnVoucherChanges_1 = require("../../hooks/useOnVoucherChanges");
const BASKET_COUPON_CODE_ID = 'basket-coupon-code';
const BASKET_COUPON_WEBCODE_ID = 'basket-coupon-webcode';
const VoucherBlock = ({ submitButtonLabel, onSubmit }) => {
    var _a;
    const { iconClasses, toggleProps } = (0, andes_react_1.useCollapsible)();
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const [successMessages, setSuccessMessages] = (0, react_1.useState)([]);
    const dispatch = (0, react_redux_1.useDispatch)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const fetchProducts = () => dispatch(BasketActions_creators_1.basketActions.createGetAction());
    const { onVoucherChanges } = (0, useOnVoucherChanges_1.useOnVoucherChanges)();
    const onVoucherAddSuccessAnalytics = (code) => dispatch(AnalyticsActions_creators_1.analyticsActions.voucherAdded(code));
    const [state] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const validateRegex = (_a = state === null || state === void 0 ? void 0 : state.info) === null || _a === void 0 ? void 0 : _a.validateRegex;
    const [showWebcode, setShowWebcode] = (0, react_1.useState)(false);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy]);
    const validationSchema = Yup.object({
        code: Yup.string().required(t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.barcode.errormessage`)),
        webcode: Yup.string().when('code', {
            is: true,
            then: schema => showWebcode
                ? schema.required(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.webcode.errormessage`)
                : schema,
        }),
    });
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: { code: '', webcode: '' },
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    const onCodeChange = (code) => {
        validateRegex && setShowWebcode(new RegExp(validateRegex).test(code));
    };
    const fetchPaymentInfo = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        yield QueryClientUtil_1.default.refetchQueries({ queryKey: common_queries_1.checkoutQueryKeys.getPaymentInfo(), stale: false });
        yield onVoucherChanges();
    });
    const onVoucherAddSuccess = (code) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        onVoucherAddSuccessAnalytics(code);
        fetchProducts();
        fetchPaymentInfo();
        onVoucherChanges();
    });
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const handleSubmit = (_b) => tslib_1.__awaiter(void 0, [_b], void 0, function* ({ code, webcode }) {
        var _c, _d;
        if (showWebcode) {
            try {
                yield onSubmit(code, webcode);
                setSuccessMessages(previousSuccessMessages => [...previousSuccessMessages, { amount: 0 }]);
                formProps.reset();
                onVoucherAddSuccess(code);
                setIsOpen(false);
            }
            catch (error) {
                const message = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message;
                if (message) {
                    if (isDesktop) {
                        formProps.setError('code', { message });
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BASKET_COUPON_CODE_ID), isDesktop);
                    }
                    else {
                        formProps.setError('webcode', { message });
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BASKET_COUPON_WEBCODE_ID), isDesktop);
                    }
                    dispatch(AnalyticsActions_creators_1.analyticsActions.formsErrorMessage({
                        ['BuyService._postVoucher']: message,
                        ['buy-voucher.voucher-code_1']: message,
                    }));
                }
            }
        }
        else {
            formProps.setError('code', {
                type: 'custom',
                message: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.mediacode.error`),
            });
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BASKET_COUPON_CODE_ID), isDesktop);
        }
    });
    const voucherForm = ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), name: "voucher-form", children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 1, gapX: 4, gapY: 6, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", children: [(0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "code", required: true, children: (_a) => {
                                    var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('checkout:checkout.basket.voucher.barcode.label'), name: fieldProps.name, id: BASKET_COUPON_CODE_ID, dataTestId: fieldProps.name, error: error, maxLength: 50, onKeyUp: () => {
                                            onCodeChange(fieldProps.value);
                                        }, onClick: event => event.stopPropagation() })));
                                } }), showWebcode && ((0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "webcode", children: (_a) => {
                                    var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('checkout:checkout.basket.voucher.webcode.label'), name: fieldProps.name, id: BASKET_COUPON_WEBCODE_ID, dataTestId: fieldProps.name, error: error, maxLength: 50, onClick: event => event.stopPropagation() })));
                                } })), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { type: "submit", theme: "neutral", variant: "secondary", text: submitButtonLabel, onClick: (event) => {
                                    event.stopPropagation();
                                } })] }) }) }) }) })));
    return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard, { onClick: toggle, children: (0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard.Cell, { section: "content", children: [(0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleToggle, Object.assign({}, toggleProps, { tag: "div", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { contentAlign: 'between', children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.GiftIcon, color: "subdued", size: "sm" }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.voucher.title`) })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, className: iconClasses, children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.CaretDownIcon }) })] }) })), (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, { isOpen: isOpen, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "2", children: voucherForm }) }), (successMessages === null || successMessages === void 0 ? void 0 : successMessages.length) > 0 && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "2", children: successMessages.map(() => ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.success`), status: "success", hasCloseBtn: false }))) }) }))] }) }));
};
exports.default = VoucherBlock;
