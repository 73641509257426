"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const StoreAPI_1 = require("../../../../api/store/StoreAPI");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const StorageKeysConstants_1 = require("../../../../constants/StorageKeysConstants");
const LocalStorageUtil_1 = require("../../../../util/LocalStorageUtil");
const InStoreFilterMobile = ({ inStoreFilter, activeStoreId, handleOpenPopup, updateInStoreFilter, updateStoreId, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const handleFilterChange = () => updateStoreId(inStoreFilter === null || inStoreFilter === void 0 ? void 0 : inStoreFilter.storeId);
    const getStoreInfo = (storeId, servicesApi, requestParams) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const store = yield StoreAPI_1.default.getStoreInfoByStoreId(servicesApi, requestParams, storeId);
            updateInStoreFilter === null || updateInStoreFilter === void 0 ? void 0 : updateInStoreFilter(store);
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY, store);
        }
        catch (_a) { }
    });
    (0, react_1.useEffect)(() => {
        if (!activeStoreId || (activeStoreId && activeStoreId === (inStoreFilter === null || inStoreFilter === void 0 ? void 0 : inStoreFilter.storeId))) {
            return;
        }
        getStoreInfo(activeStoreId, servicesEndpoint, defaultRequestParams);
    }, [activeStoreId, servicesEndpoint, defaultRequestParams, inStoreFilter]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "4", backgroundColor: "informative-bg", borderRadius: "lg", children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Grid, { templateColumns: "20px 1fr 32px", gap: "s", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Icon, { name: "storefront", size: "md" }), (0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: t('product.lister.inStore.mobile.from') }), inStoreFilter && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.SUBTLE, children: [inStoreFilter.storeName, ' '] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, text: inStoreFilter ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { emphasized: true, inline: true, color: cotopaxi_1.TextColor.SUBTLE, children: ["(", t('product.lister.inStore.mobile.change'), ")"] })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.SUBTLE, children: t('product.lister.inStore.mobile.chooseStore') })), size: cotopaxi_1.ButtonSize.SMALL, variant: cotopaxi_1.ButtonVariant.LINK, onClick: handleOpenPopup })] }), (0, jsx_runtime_1.jsx)(andes_react_1.Switch, { checked: !!activeStoreId, id: "in_store_filter", onChange: handleFilterChange })] }) })] }));
};
exports.default = InStoreFilterMobile;
