"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsSummary = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const CartTotal_1 = require("../../cart-total/CartTotal");
const ProductTileOrderOverview_1 = require("../../product-tile/order-overview/ProductTileOrderOverview");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BasketActions_creators_1 = require("../../../redux/basket/actions/BasketActions.creators");
const CheckoutStepContext_1 = require("../../../context/CheckoutStepContext");
const useOnVoucherChanges_1 = require("../../../hooks/useOnVoucherChanges");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const axios_1 = require("axios");
const CheckoutContext_1 = require("../../../context/CheckoutContext");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const ProductsSummary = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const { step } = (0, CheckoutStepContext_1.useCheckoutStepState)();
    const { termsUrl } = (0, CheckoutContext_1.useCheckoutConfig)();
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const vouchers = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketVouchersState)());
    const pricing = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const isBasketLocked = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketLockState)());
    const basketTotalQuantity = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const reduxDispatch = (0, react_redux_1.useDispatch)();
    const getProducts = () => reduxDispatch(BasketActions_creators_1.basketActions.createGetAction());
    const { onVoucherChanges } = (0, useOnVoucherChanges_1.useOnVoucherChanges)();
    const queryClient = (0, react_query_1.useQueryClient)();
    const { deleteVoucher } = (0, common_queries_1.basketMutations)({ defaultRequestParams, servicesEndpoint });
    const { mutate: deleteVoucherMutation } = (0, react_query_1.useMutation)(deleteVoucher);
    const handleDeleteVoucher = (barcode) => {
        deleteVoucherMutation(barcode, {
            onSuccess: () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
                getProducts();
                if (step === CheckoutStepContext_1.CheckoutStep.PAYMENT) {
                    yield queryClient.refetchQueries({ queryKey: common_queries_1.checkoutQueryKeys.getPaymentInfo(), stale: false }); // this will trigger paymentInfo update in CheckoutPaymentContainer
                    yield onVoucherChanges();
                }
                // if (step === CheckoutStep.FAST_CHECKOUT) {
                //   getFastOverviewInfo();
                //   onVoucherChanges();
                // }
            }),
        });
    };
    const { data: terms } = (0, react_query_1.useQuery)({
        queryKey: ['terms', termsUrl],
        queryFn: () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: termsUrl,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        }),
        enabled: !!termsUrl,
    });
    if (!products) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "center", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.order.title.page`) }), !isBasketLocked && ((0, jsx_runtime_1.jsx)(andes_react_1.Link, { theme: "neutral", variant: "primary", href: `${root}checkout/basket-overview.html`, dataTestId: "back_to_basket_overview", children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.edit`) }))] }), (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "3", children: products.map(product => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ProductTileOrderOverview_1.ProductTileOrderOverview, { product: product }), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {})] }, product.productCode))) }), (0, jsx_runtime_1.jsx)(CartTotal_1.CartTotal, Object.assign({ vouchers: vouchers }, pricing, { totalQuantity: basketTotalQuantity, onDeleteVoucher: handleDeleteVoucher })), terms && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subdued", children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: terms }) }))] }));
};
exports.ProductsSummary = ProductsSummary;
